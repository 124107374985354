import React, { useEffect, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import DataGrid from 'react-data-grid';
import { supabase } from '../client/client';

const FetchMaintenance = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filters, setFilters] = useState({
    title: '',
    detail: '',
    date_maintenance: '',
    date_next_maintenance: '',
  });

  const handleImageClick = (row) => {
    Navigate(`/informacion/${row.id}`);
  };

  const handleFilterChange = (filterName, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: value,
    }));
  };

  const columnsMantenimiento = [
    {
      field: 'image1',
      name: 'Foto',
      key: 'image1',
      width: 30,
      renderCell: ({ row }) => (
        <Link to={`/informacion/${row.id_equipo}`} onClick={() => handleImageClick(row)}>
          <img className="" src={row.image1} alt={row.title} />
        </Link>
      ),
    },
    { field: "attendant", name: "Encargado", width: 170, key: "attendant" },
    {
      field: 'title',
      name: 'Nombre',
      width: 150,
      key: 'title',
      renderCell: ({ row }) => (
        <Link to={`/equipos/${row.id}/editar`} underline="always">
          {row.title}
        </Link>
      ),
    },
    { field: 'detail', name: 'Detalle', width: 180, key: 'detail' },
    { field: 'date_maintenance', name: 'Fecha mantenimiento', width: 180, key: 'date_maintenance' },
    { field: 'observations', name: 'Observaciones', width: 200, key: 'observations' },
    { field: 'verification', name: 'Verificación', width: 110, key: 'verification' },
    { field: 'date_next_maintenance', name: 'Fecha próximo mantenimiento', width: 250, key: 'date_next_maintenance' },
    { field: 'correctivo', name: 'Correctivo', width: 150, key: 'correctivo' },
    { field: 'date_correctivo', name: 'Fecha de correctivo', width: 200, key: 'date_correctivo' },
    { field: 'frecuency_maintenance', name: 'Frecuencia de mantenimiento', width: 250, key: 'frecuency_maintenance' },
  ];

  const fetchData = async () => {
    try {
      const { data: maintenanceData, error: maintenanceError } = await supabase
        .from('maintenance')
        .select('*');
      if (maintenanceError) {
        throw maintenanceError;
      }
      const maintenanceIds = maintenanceData.map(date_maintenance => date_maintenance.id_equipo);
      const { data: equiposData, error: equiposError } = await supabase
        .from('equipos')
        .select('id, title, attendant, image1')
        .in('id', maintenanceIds);
      if (equiposError) {
        throw equiposError;
      }
      const combinedData = maintenanceData.map((date_maintenance) => {
        const equipo = equiposData.find((equipo) => equipo.id === date_maintenance.id_equipo);
        return {
          ...date_maintenance,
          title: equipo.title,
          attendant: equipo.attendant,
          image1: equipo.image1,
        };
      });

      // Ordenar los datos en base a la fecha de mantenimiento (de la más reciente a la más antigua)
      const sortedData = combinedData.slice().sort((a, b) => new Date(b.date_maintenance) - new Date(a.date_maintenance));

      // Filtrar los datos en base a los filtros
      const filteredData = sortedData.filter((row) =>
        Object.entries(filters).every(([key, value]) =>
          row[key].toLowerCase().includes(value.toLowerCase())
        )
      );

      setData(sortedData); // Actualizar data
      setFilteredData(filteredData); // Actualizar filteredData
    } catch (error) {
      console.error('Error fetching data:', error.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    // Filtrar los datos en base a los filtros
    const filteredData = data.filter((row) =>
      Object.entries(filters).every(([key, value]) =>
        row[key].toLowerCase().includes(value.toLowerCase())
      )
    );

    setFilteredData(filteredData);
  }, [filters, data]);

  const gridStyle = {
    minHeight: '500px',
  };

  return (
    <>
      <div className="flex items-center gap-2 mb-4 mt-5">
        <input
          type="text"
          placeholder="Filtrar por nombre"
          value={filters.title}
          onChange={(e) => handleFilterChange('title', e.target.value)}
        />
        <input
          type="text"
          placeholder="Filtrar por detalle"
          value={filters.detail}
          onChange={(e) => handleFilterChange('detail', e.target.value)}
        />
        <input
          type="text"
          placeholder="Filtrar por fecha mantenimiento"
          value={filters.date_maintenance}
          onChange={(e) => handleFilterChange('date_maintenance', e.target.value)}
        />
        <input
          type="text"
          placeholder="Filtrar por fecha próximo mantenimiento"
          value={filters.date_next_maintenance}
          onChange={(e) => handleFilterChange('date_next_maintenance', e.target.value)}
        />
      </div>
      <DataGrid rows={filteredData} columns={columnsMantenimiento} style={gridStyle} rowHeight={50} minHeight={400} className="rdg-light" />
    </>
  );
};

export default FetchMaintenance;
