const SummaryTile = ({ title, subTitle, icon }) => {
  return (
    <div className="flex items-center">
      <div className="bg-[#e9e5e5] rounded-lg m-5 p-2 shadow-xl">
        {icon}
      </div>
      <div className="flex gap-2">
        <p className="font-black text-4xl">{title}</p>
        <p>{subTitle}</p>
      </div>
    </div>
  );
};

export default SummaryTile;
