import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DataGrid from "react-data-grid";
import "jspdf-autotable";
import { supabase } from "../client/client";
import "react-data-grid/lib/styles.css";


const FetchBackups = () => {
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [filters, setFilters] = useState({
        attendant: "",
        title: "",
        date_next_backup: "",
        date_backup: "",
    });

    const handleFilterChange = (filterName, value) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            [filterName]: value,
        }));
    };


    const columnsBackups = [
        { field: "attendant", name: "Encargado", width: 250, key: "attendant" },
        {
            field: "title",
            name: "Nombre",
            width: 250,
            key: "title",
            renderCell: ({ row }) => {
                return (
                    <Link to={`/backups/${row.id}/editar`} underline="always">
                        {row.title}
                    </Link>
                );
            },
        },
        { field: "date_backup", name: "Fecha realizado", width: 240, key: "date_backup" },
        { field: "date_next_backup", name: "Fecha programada", width: 240, key: "date_next_backup" },
        { field: 'frecuency_maintenance', name: 'Frecuencia de mantenimiento', width: 250, key: 'frecuency_maintenance' },
    ];

    const gridStyle = {
        minHeight: '500px',
    };

    const fetchData = async () => {
        try {
            const { data: backupsData, error: backupsError } = await supabase
                .from('backups')
                .select('*');
            if (backupsError) {
                throw backupsError;
            }
            const backupsIds = backupsData.map(date_backup => date_backup.id_equipo);
            const { data: equiposData, error: equiposError } = await supabase
                .from('equipos')
                .select('id, title, attendant')
                .in('id', backupsIds);
            if (equiposError) {
                throw equiposError;
            }
            const combinedData = backupsData.map(date_backup => {
                const equipo = equiposData.find(equipo => equipo.id === date_backup.id_equipo);
                return {
                    ...date_backup,
                    title: equipo.title,
                    attendant: equipo.attendant
                };
            });
            setData(combinedData);
        } catch (error) {
            console.error("Error fetching data:", error.message);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        // Filtrar los datos en base a los filtros
        const filteredData = data.filter((row) =>
            Object.entries(filters).every(([key, value]) =>
                row[key].toLowerCase().includes(value.toLowerCase())
            )
        );

        setFilteredData(filteredData);
    }, [filters, data]);

    useEffect(() => {
        // Filtrar y ordenar los datos en base a los filtros
        const filteredAndSortedData = data
            .filter((row) =>
                Object.entries(filters).every(([key, value]) =>
                    row[key].toLowerCase().includes(value.toLowerCase())
                )
            )
            .sort((a, b) => new Date(b.date_backup) - new Date(a.date_backup));

        setFilteredData(filteredAndSortedData);
    }, [filters, data]);



    return (
        <>
            <div className="flex items-center gap-2 mb-4 mt-5">
                <input
                    type="text"
                    placeholder="Filtrar por encargado"
                    value={filters.attendant}
                    onChange={(e) => handleFilterChange("attendant", e.target.value)}
                />
                <input
                    type="text"
                    placeholder="Filtrar por nombre"
                    value={filters.title}
                    onChange={(e) => handleFilterChange("title", e.target.value)}
                />
                <input
                    type="text"
                    placeholder="Filtrar por fecha programada"
                    value={filters.date_next_backup}
                    onChange={(e) => handleFilterChange("date_next_backup", e.target.value)}
                />
                <input
                    type="text"
                    placeholder="Filtrar por fecha realizada"
                    value={filters.date_backup}
                    onChange={(e) => handleFilterChange("date_backup", e.target.value)}
                />
            </div>
            <DataGrid rows={filteredData} columns={columnsBackups} style={gridStyle} rowHeight={50} minHeight={400} className="rdg-light" />
        </>
    )
}

export default FetchBackups