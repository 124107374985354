import { useState } from 'react';
import { supabase } from '../client/client';
import './EquipmentForm.css';
import Layout from './Layout';
import { useNavigate } from 'react-router-dom';

const UserForm = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        password: '',
        role: '',
    });

    const [showSuccessDialog, setShowSuccessDialog] = useState(false);

    const roleOptions = [
        'client',
        'admin',
    ];

    const handleChange = (e) => {
        const { name, value } = e.target;
        const formattedValue = name === 'name' ? value.toUpperCase() : value;

        setFormData((prevData) => ({
            ...prevData,
            [name]: formattedValue,
        }));
    };
    const handleSubmit = async (e) => {
        e.preventDefault();

        const now = new Date().toISOString();

        const { data, error } = await supabase.from('users').upsert([
            {
                updated_at: now,
                ...formData,
            },
        ]);

        if (error) {
            console.error('Error al guardar en la base de datos:', error);
        } else {
            console.log('Datos guardados exitosamente:', data);
            // Mostrar el cuadro de diálogo de éxito
            setShowSuccessDialog(true);
        }
    };

    const handleAccept = () => {
        setShowSuccessDialog(false);
        window.location.reload();
        navigate("/usuarios");
    };

    const sortedRoleOptions = roleOptions.slice().sort();

    return (
        <Layout>
            <form className="equipment-form" onSubmit={handleSubmit}>
                <div className="form-row">
                    <div className="field">
                        <label className='text-xs font-semibold'>
                            Nombre:
                            <input
                                type="text"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                            />
                        </label>
                    </div>
                    <div className="field">
                        <label className='text-xs font-semibold'>
                            Email:
                            <input
                                type="text"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                            />
                        </label>
                    </div>
                    <div className="field">
                        <label className='text-xs font-semibold'>
                            Contraseña:
                            <input
                                type="password"
                                name="password"
                                value={formData.password}
                                onChange={handleChange}
                            />
                        </label>
                    </div>

                    <div className="field">
                        <label className='text-xs font-semibold'>
                            Rol:
                            <div className="styled-select">
                                <select
                                    name="role"
                                    value={formData.role}
                                    onChange={handleChange}
                                    className="styled-select"
                                >
                                    <option value="">Selecciona una opción</option>
                                    {sortedRoleOptions.map((option) => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </label>
                    </div>

                </div>



                <div className="form-row">
                    <button className="bg-green hover:bg-green-800 transition-colors duration-300 text-base cursor-pointer mt-7 text-white font-semibold py-2 px-4 rounded-md " type="submit">Guardar</button>
                </div>

                {showSuccessDialog && (
                    <div className="success-dialog">
                        <p>Se ha guardado exitosamente</p>
                        <button onClick={handleAccept} className="bg-green hover:bg-green-800 transition-colors duration-300 text-base cursor-pointer mt-7 text-white font-semibold py-2 px-4 rounded-md w-full">Aceptar</button>
                    </div>
                )}
            </form>
        </Layout>
    );
};

export default UserForm;
