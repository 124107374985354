import { Route, Routes } from "react-router-dom";
import PerfilPage from "../components/Perfil";
import HomePage from "../components/Home";
import Areas from "../components/Areas";
import ProductCardArea from "../components/ProductCardArea";
import Dashboard from "../components/Dashboard";
import Equipos from "../components/Equipos";
import Usuarios from "../components/Usuarios";
import ProductDetailPage from "../components/informacion/ProductDetailPage";
import LoginForm from "../components/LoginForm";
import EquipmentForm from "../components/EquipmentForm";
import UserForm from "../components/UserForm";
import NotFound from "../components/NotFound";
import SearchResultPage from "../components/SearchedProducts";
import BackupsForm from "../components/BackupsForm";
import BackupsServerForm from "../components/BackupsServerForm";
import MaintenanceForm from "../components/MaintenanceForm";
import SoftwareForm from "../components/SoftwareForm";
import PricesForm from "../components/PricesForm";
import ProviderForm from "../components/ProviderForm";

const Views = () => {
  return (
    <Routes>
      <Route path="/" index element={<LoginForm />} />
      <Route path="/home" element={<HomePage />} />
      <Route path="/perfil" element={<PerfilPage />} />
      <Route path="/areas" element={<Areas />} >
        
        {/* <Route path="producto" >
        <Route path=":area" element={<ProductCardArea />} />
      </Route> */}
      </Route>
      <Route path="/producto/:area" element={<ProductCardArea />}/>
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/equipos" element={<Equipos />} />
      <Route path="/usuarios" element={<Usuarios />} />
      <Route exact path="/informacion/:slug" element={<ProductDetailPage />} />
      <Route path="/formulario-equipo" element={<EquipmentForm />} />
      <Route path="/formulario-usuarios" element={<UserForm />} />
      <Route path="/busqueda/:term" element={<SearchResultPage/>} />
      <Route path="/equipos/:id/editar" element={<EquipmentForm />} />
      <Route path="/backups/crear" element={<BackupsForm />} />
      <Route path="/backups-server/crear" element={<BackupsServerForm />} />
      <Route path="/mantenimiento/crear" element={<MaintenanceForm />} />
      <Route path="/software/crear" element={<SoftwareForm />} />
      <Route path="/costos/crear" element={<PricesForm />} />
      <Route path="/proveedor/crear" element={<ProviderForm />} />

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default Views;
