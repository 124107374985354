import React, { useEffect, useState } from 'react';
import Layout from './Layout';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../client/client';
import { useLogin } from '../context/LoginContext';

const SoftwareForm = () => {
    const [loading, setLoading] = useState(true);
    const [showSuccessDialog, setShowSuccessDialog] = useState(false);
    const { getSessionAuth } = useLogin();
    const navigate = useNavigate();

    useEffect(() => {
        const sessionAuth = async () => {
            const session = await getSessionAuth();
            if (session) {
                setLoading(false);
            } else {
                navigate("/", { replace: true });
            }
        };
        sessionAuth();
    }, [getSessionAuth, navigate]);

    const [formData, setFormData] = useState({
        description: '',
        license: '',
        license_duration: '',
        date_expiry: '',
    });

    const [equiposList, setEquiposList] = useState([]);
    const [selectedEquipo, setSelectedEquipo] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data: equiposData, error: equiposError } = await supabase
                    .from('equipos')
                    .select('id, title, attendant')
                    .eq('area', 'ARBITRAJE');

                if (equiposError) {
                    console.error('Error fetching equipos data:', equiposError);
                } else {
                    setEquiposList(equiposData);
                }
            } catch (error) {
                console.error('Error fetching equipos data:', error.message);
            }
        };

        fetchData();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;

        let formattedValue = value;

        if (name === 'date_next_maintenance' || name === 'date_maintenance') {
            const parts = value.split('/');
            if (parts.length === 3) {
                formattedValue = `${parts[2]}-${parts[1]}-${parts[0]}`;
            }
        }

        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: formattedValue,
        }));
    };

    const handleEquipoChange = (e) => {
        const selectedId = e.target.value;

        setSelectedEquipo(selectedId);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const { error } = await supabase.from('licencias').upsert([
                {
                    id_equipo: selectedEquipo,
                    description: formData.description,
                    license: formData.license,
                    license_duration: formData.license_duration,
                    date_expiry: formData.date_expiry,
                },
            ]);

            if (error) {
                console.error('Error updating data:', error);
            } else {
                setShowSuccessDialog(true);
            }
        } catch (error) {
            console.error('Error updating data:', error.message);
        }
    };

    const handleAccept = () => {
        setShowSuccessDialog(false);
        navigate("/equipos");
    };


    return (
        <Layout>
            <form onSubmit={handleSubmit}>
                <h1 className='text-3xl font-bold text-green flex justify-center'>SOFTWARE</h1>
                <div className="form-row pt-10">
                    <div className="field">
                        <label className='text-xs font-semibold'>
                            Descripción de la licencia:
                            <input
                                type="text"
                                name="description"
                                value={formData.description}
                                onChange={handleChange}
                            />
                        </label>
                    </div>
                    <div className="field">
                        <label className='text-xs font-semibold'>
                            Licencia:
                            <input
                                type="text"
                                name="license"
                                value={formData.license}
                                onChange={handleChange}
                            />
                        </label>
                    </div>
                    <div className="field">
                        <label className='text-xs font-semibold'>
                            Duración de la icencia:
                            <input
                                type="text"
                                name="license_duration"
                                value={formData.license_duration}
                                onChange={handleChange}
                            />
                        </label>
                    </div>
                    <div className="field">
                        <label className='text-xs font-semibold'>
                            Fecha de expiración:
                            <input
                                type="date"
                                name="date_expiry"
                                value={formData.date_expiry}
                                onChange={handleChange}
                            />
                        </label>
                    </div>
                </div>

                <div className="form-row">
                    <button className="bg-[#058237] hover:bg-green transition-colors duration-300 text-base cursor-pointer mt-7 text-white font-semibold py-2 px-4 rounded-md w-full" type="submit">Guardar</button>
                </div>

                {showSuccessDialog && (
                    <div className="success-dialog">
                        <p>Se ha guardado exitosamente</p>
                        <button onClick={handleAccept} className="bg-[#058237] hover:bg-green transition-colors duration-300 text-base cursor-pointer mt-7 text-white font-semibold py-2 px-4 rounded-md w-full">Aceptar</button>
                    </div>
                )}
            </form>
        </Layout>
    )
}

export default SoftwareForm