import { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useLogin } from '../context/LoginContext';
import { supabase } from '../client/client';
import Loading from './Loading';

const ProductCard = () => {
  const [processProducts, setProcessProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState(null);
  const { getSessionAuth } = useLogin();
  const navigate = useNavigate();
  const { area } = useParams();

  useEffect(() => {
    const sessionAuth = async () => {
      const session = await getSessionAuth();
      if (session) {
        await getUser(session.id);
        setLoading(false);
      } else {
        navigate("/", { replace: true });
      }
    };
    sessionAuth();
  }, []);

  const getUser = async (id) => {
    const { error, data } = await supabase
      .from("users")
      .select()
      .eq(`id`, id)
      .single();
    if (error) throw error.message;
    setUserData(data);
  };

  useEffect(() => {
    async function fetchUserProducts() {
      try {
        if (!userData) return;
        const { data, error } = await supabase
          .from('equipos')
          .select(`*`)
          .eq('attendant', userData.name);

        if (error) {
          console.error('Error fetching data:', error.message, error.details);
          return;
        }

        setProcessProducts(data);
      } catch (error) {
        console.error('Unexpected error:', error);
      }
    }
    fetchUserProducts();
  }, [userData]);

  return (
    <div>
      {loading && <Loading />}
      <div className="grid md:grid-cols-5 grid-cols-3 gap-8 mt-10">
        {processProducts.map((product) => (
          <Link
            to={`/informacion/${product.id}`}
            key={product.id}
            className="flex flex-col gap-2 border border-zinc-300 p-5 hover:bg-zinc-100 transition-colors duration-200"
          >
            <img
              src={`/images/${product.image ? product.image[0] : "producto.jpeg"
                }`}
              alt={`${product.title}`}
              className="w-full aspect-square"
            />
            <h3 className="text-[#058237] font-bold text-lg text-wrap">
              {product.title}
            </h3>
            <h4 className="text-black font-semibold text-xs">{product.area}</h4>
          </Link>
        ))}
      </div>
    </div>
    
  );
};

export default ProductCard;
