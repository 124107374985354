

import React, { useEffect, useState } from 'react';
import Layout from './Layout';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../client/client';
import { useLogin } from '../context/LoginContext';

const BackupsForm = () => {
  const [loading, setLoading] = useState(true);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const { getSessionAuth } = useLogin();
  const frecuencyOptions = ['Cada 2 meses','Cada 6 meses', 'Cada mes', 'Cada año'];
  const navigate = useNavigate();

  useEffect(() => {
    const sessionAuth = async () => {
      const session = await getSessionAuth();
      if (session) {
        setLoading(false);
      } else {
        navigate("/", { replace: true });
      }
    };
    sessionAuth();
  }, [getSessionAuth, navigate]);

  const [formData, setFormData] = useState({
    date_backup: '',
    date_next_backup: '',
    frecuency_maintenance: '',
  });

  const [equiposList, setEquiposList] = useState([]);
  const [selectedEquipo, setSelectedEquipo] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: equiposData, error: equiposError } = await supabase
          .from('equipos')
          .select('id, title, attendant')
          .eq('area', 'ARBITRAJE')
          .in('title', ['CPU', 'LAPTOP']);

        if (equiposError) {
          console.error('Error fetching equipos data:', equiposError);
        } else {
          setEquiposList(equiposData);
        }
      } catch (error) {
        console.error('Error fetching equipos data:', error.message);
      }
    };

    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    let formattedValue = value;

    if (name === 'date_next_backup' || name === 'date_backup') {
      const parts = value.split('/');
      if (parts.length === 3) {
        formattedValue = `${parts[2]}-${parts[1]}-${parts[0]}`;
      }
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: formattedValue,
    }));
  };

  const handleEquipoChange = (e) => {
    const selectedId = e.target.value;

    setSelectedEquipo(selectedId);
  };



  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const { error } = await supabase.from('backups').upsert([
        {
          id_equipo: selectedEquipo,
          date_backup: formData.date_backup,
          date_next_backup: formData.date_next_backup,
        },
      ]);

      if (error) {
        console.error('Error updating data:', error);
      } else {
        setShowSuccessDialog(true);
      }
    } catch (error) {
      console.error('Error updating data:', error.message);
    }
  };


  const handleAccept = () => {
    setShowSuccessDialog(false);
    navigate("/equipos");
  };

  return (
    <Layout>
      <form onSubmit={handleSubmit}>
        <h1 className='text-3xl font-bold text-[#058237] flex justify-center pb-10'>BACKUPS</h1>
        <div className="form-row">
          <div className="field">
            <label className='text-xs font-semibold'>
              Equipo:
              <select
                name="equipo"
                value={selectedEquipo || ''}
                onChange={handleEquipoChange}
              >
                <option value="" disabled>Select an equipo</option>
                {equiposList.map((equipo) => (
                  <option key={equipo.id} value={equipo.id}>
                    {`${equipo.title} - ${equipo.attendant}`}
                  </option>
                ))}
              </select>
            </label>
          </div>
        </div>
        <div className="form-row">
          <div className="field">
            <label className='text-xs font-semibold'>
              Fecha backup:
              <input
                type="date"
                name="date_backup"
                value={formData.date_backup}
                onChange={handleChange}
              />
            </label>
          </div>
          <div className="field">
            <label className='text-xs font-semibold'>
              Fecha del próximo backup:
              <input
                type="date"
                name="date_next_backup"
                value={formData.date_next_backup}
                onChange={handleChange}
              />
            </label>
          </div>
          <div className="field">
                        <label className='text-xs font-semibold'>
                            Frecuencia de mantenimiento:
                            <div className="styled-select">
                                <select
                                    name="frecuency_maintenance"
                                    value={formData.frecuency_maintenance}
                                    onChange={handleChange}
                                    className="styled-select"
                                >
                                    <option value="">Selecciona una opción</option>
                                    {frecuencyOptions.map((option) => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </label>
                    </div>
        </div>
        <div className="form-row">
          <button className="bg-[#058237] hover:bg-green transition-colors duration-300 text-base cursor-pointer mt-7 text-white font-semibold py-2 px-4 rounded-md w-full" type="submit">Guardar</button>
        </div>

        {showSuccessDialog && (
          <div className="success-dialog">
            <p>Se ha guardado exitosamente</p>
            <button onClick={handleAccept} className="bg-[#058237] hover:bg-green transition-colors duration-300 text-base cursor-pointer mt-7 text-white font-semibold py-2 px-4 rounded-md w-full">Aceptar</button>
          </div>
        )}
      </form>
    </Layout>
  );
};

export default BackupsForm;
