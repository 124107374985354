import { createContext, useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { supabase } from "../client/client"
export const LoginContext = createContext();

export const useLogin = () => {
  const context = useContext(LoginContext);
  if (!context) {
    throw new Error("useLogin debe estar dentro del proveedor LoginContext");
  }
  return context;
};

export const LoginContextProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  const [error, setError] = useState("")
  const navigate = useNavigate()

  useEffect(() => {
    supabase.auth.onAuthStateChange(async (event, session) => {
      if (session == null) {
        navigate("/", { replace: true });
      } else {
        setUser(session?.user);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const signInMail = async ({ email, password }) => {
    try {
      const { error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });
      if (error) setError(error?.message);
      navigate("/home");
      // setUser(data.user);
    } catch (error) {
      console.error(error);
    }
  };

  const getSessionAuth = async () => {
    try {
      const { data, error } = await supabase.auth.getSession();
      if (error) throw error;
      console.log("SESSION", data);
      return data.session.user;
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <LoginContext.Provider
      value={{
        user,
        error,
        signInMail,
        getSessionAuth,
      }}
    >
      {children}
    </LoginContext.Provider>
  )
}
