import "./App.css";
import Views from "./router/Views";
import { LoginContextProvider } from "./context/LoginContext";
import { UserContextProvider } from "./context/UserContext";

function App() {
  return (
    <LoginContextProvider>
      <UserContextProvider>
        <Views />
      </UserContextProvider>
    </LoginContextProvider>
  );
}

export default App;
