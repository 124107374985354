import React, { useEffect, useState } from 'react';
import Layout from './Layout';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../client/client';
import { useLogin } from '../context/LoginContext';

const PricesForm = () => {
    const [loading, setLoading] = useState(true);
    const [showSuccessDialog, setShowSuccessDialog] = useState(false);
    const { getSessionAuth } = useLogin();
    const frecuencyOptions = ['Cada 2 meses', 'Cada 6 meses', 'Cada mes', 'Cada año'];
    const navigate = useNavigate();
    const [prefix, setPrefix] = useState("S/.");

    useEffect(() => {
        const sessionAuth = async () => {
            const session = await getSessionAuth();
            if (session) {
                setLoading(false);
            } else {
                navigate("/", { replace: true });
            }
        };
        sessionAuth();
    }, [getSessionAuth, navigate]);

    const [formData, setFormData] = useState({
        costos: 'S/.'
    });

    const [equiposList, setEquiposList] = useState([]);
    const [selectedEquipo, setSelectedEquipo] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data: equiposData, error: equiposError } = await supabase
                    .from('equipos')
                    .select('id, title, attendant,area');

                if (equiposError) {
                    console.error('Error fetching equipos data:', equiposError);
                } else {
                    // Ordenar el array equiposData en orden alfabético por el campo title
                    const sortedEquipos = equiposData.sort((a, b) => {
                        const aTitle = a.title.match(/^(\D+)(\d*)$/);
                        const bTitle = b.title.match(/^(\D+)(\d*)$/);

                        if (aTitle && bTitle) {
                            const aText = aTitle[1].trim();
                            const bText = bTitle[1].trim();
                            const aNumber = aTitle[2] ? parseInt(aTitle[2], 10) : 0;
                            const bNumber = bTitle[2] ? parseInt(bTitle[2], 10) : 0;

                            if (aText === bText) {
                                return aNumber - bNumber;
                            } else {
                                return aText.localeCompare(bText);
                            }
                        } else {
                            return a.title.localeCompare(b.title);
                        }
                    });
                    setEquiposList(sortedEquipos);
                }
            } catch (error) {
                console.error('Error fetching equipos data:', error.message);
            }
        };

        fetchData();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;

        // Si el valor es solo "S/." o está vacío, actualiza solo el prefijo
        if (value === 'S/.' || value === '') {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: value,
            }));
        } else {
            // Si el valor tiene algo más, concatena "S/." con el valor ingresado
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: `${value}`,
            }));
        }
    };

    const handleEquipoChange = (e) => {
        const selectedId = e.target.value;
        setSelectedEquipo(selectedId);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const { error } = await supabase.from('costos').upsert([
                {
                    id_equipo: selectedEquipo,
                    costos: formData.costos,
                },
            ]);

            if (error) {
                console.error('Error updating data:', error);
            } else {
                setShowSuccessDialog(true);
            }
        } catch (error) {
            console.error('Error updating data:', error.message);
        }
    };

    const handleAccept = () => {
        setShowSuccessDialog(false);
        navigate("/equipos");
    };

    return (
        <Layout>
            <form onSubmit={handleSubmit}>
                <h1 className='text-3xl font-bold text-[#058237] flex justify-center pb-10'>COSTOS</h1>
                <div className="form-row">
                    <div className="field">
                        <label className='text-xs font-semibold'>
                            Equipo:
                            <select
                                name="equipo"
                                value={selectedEquipo || ''}
                                onChange={handleEquipoChange}
                            >
                                <option value="" disabled>Selecciona un equipo</option>
                                {equiposList.map((equipo) => (
                                    <option key={equipo.id} value={equipo.id}>
                                        {`${equipo.title} - ${equipo.attendant} - ${equipo.area}`}
                                    </option>
                                ))}
                            </select>
                        </label>
                    </div>
                </div>
                <div className="form-row w-[50%]">
                    <div className="field">
                        <label className='text-xs font-semibold'>
                            Costo del producto:
                            <div style={{ display: 'flex' }}>
                                <input
                                    type="text"
                                    name="costos"
                                    value={formData.costos}
                                    onChange={handleChange}
                                />
                            </div>
                        </label>
                    </div>
                </div>
                <div className="form-row">
                    <button className="bg-[#058237] hover:bg-green transition-colors duration-300 text-base cursor-pointer mt-7 text-white font-semibold py-2 px-4 rounded-md w-[50%]" type="submit">Guardar</button>
                </div>

                {showSuccessDialog && (
                    <div className="success-dialog">
                        <p>Se ha guardado exitosamente</p>
                        <button onClick={handleAccept} className="bg-[#058237] hover:bg-green transition-colors duration-300 text-base cursor-pointer mt-7 text-white font-semibold py-2 px-4 rounded-md w-full">Aceptar</button>
                    </div>
                )}
            </form>
        </Layout>
    );
};

export default PricesForm;
