
// import { useEffect, useState } from 'react';
// import { supabase } from '../client/client';
// import './EquipmentForm.css';
// import Layout from './Layout';
// import { useNavigate, useParams } from 'react-router-dom';
// import { v4 as uuidv4 } from 'uuid';

// // const CDNURL = "https://ebftkucuumypsdnaaevf.supabase.co/storage/v1/object/public/inventario_ccia/"

// const EquipmentForm = () => {
//     const navigate = useNavigate();
//     const { id } = useParams();
//     const [formData, setFormData] = useState({
//         active_type: '',
//         image1: '',
//         image2: '',
//         area: '',
//         brand: '',
//         slug: '',
//         model: '',
//         serial: '',
//         measures: '',
//         color: '',
//         state: '',
//         tags: [],
//         title: '',
//         condition_use: '',
//         quantity: 0,
//         attendant: '',
//     });

//     const [showSuccessDialog, setShowSuccessDialog] = useState(false);

//     const areaOptions = [
//         'TECNOLOGIA DE INFORMACION',
//         'CONTABILIDAD',
//         'COMERCIAL',
//         'CAJA',
//         'PROTESTOS',
//         'ORIGEN',
//         'SECRETARIA',
//         'SALON VITOR',
//         'SALON CERVESUR',
//         'LEGAL',
//         'DIRECTORIO 3',
//         'SALON 1',
//         'SALON 2',
//         'SALON 3',
//         'AUDITORIO',
//         'COCINA',
//         'BAÑO PERSONAL',
//         'BAÑO SALON CERVESUR',
//         'BAÑO AUDITORIO',
//         'HALL PRIMERA CASONA',
//         'HALL SEGUNDA CASONA',
//         'MENSAJERIA',
//         'GERENCIA',
//         'SUBGERENCIA',
//         'ARBITRAJE',
//         'ARBITRAJE 2',
//         'SECRETARIA ARBITRAL',
//     ];

//     const attendantOptions = [
//         'FABIOLA LÓPEZ CHIRINOS',
//         'KATHERINE SUEROS',
//         'RUTH VARGAS',
//         'ALEJANDRA ALVARADO',
//         'GONZALO ZAVALAGA',
//         'TAMARA NUÑEZ',
//         'DANIELA BENAVENTE',
//         'ANTONIO ESCALANTE',
//         'JOCELYN CARI',
//         'GERALDINE MARQUÉZ',
//         'MÓNICA TORRES',
//         'GUSTAVO CHIRINOS',
//         'BROSWI GALVEZ',
//         'SALOMÉ GALDOS',
//         'JUAN JAVIER',
//         'LUCIANO YNCA',
//         'LUDOVINA VILLANUEVA',
//         'JIMENA HURTADO',
//         'MILUSKA MANRIQUE',
//         'JUAN JOSE TICONA',
//         'DIEGO VALDIVIA',
//         'GIORDANO ARIAS',
//     ];

//     useEffect(() => {
//         const fetchProductData = async () => {
//             try {
//                 const { data, error } = await supabase.from('equipos').select('*').eq('id', id).single();

//                 if (error) {
//                     console.error('Error fetching product data:', error);
//                 } else {
//                     setFormData(data);
//                 }
//             } catch (error) {
//                 console.error('Error fetching product data:', error.message);
//             }
//         };

//         if (id) {
//             fetchProductData();
//         }
//     }, [id]);


//     const stateOptions = ['NUEVO', 'OBSOLETO', 'EN USO'];
//     const yesNoOptions = ['SI', 'NO'];

//     // async function handleImageChange(e) {
//     //     const imageFile = e.target.files[0];
//     //     const imageName = uuidv4() + ".jpeg";

//     //     console.log("Upload!");
//     //     const { data: uploadData, error: uploadError } = await supabase.storage
//     //         .from('inventario_ccia')
//     //         .upload(imageName, imageFile);

//     //     if (uploadError) {
//     //         console.log(uploadError);
//     //         alert("Error uploading file to Supabase");
//     //     } else {
//     //         console.log("Image uploaded successfully");

//     //         const updatedData = {
//     //             ...formData,
//     //             image1: imageName,
//     //         };

//     //         try {
//     //             const { data: updateData, error: updateError } = await supabase
//     //                 .from('equipos')
//     //                 .update(updatedData)
//     //                 .eq('id', id);

//     //             if (updateError) {
//     //                 console.error('Error updating image1 field:', updateError);
//     //             } else {
//     //                 console.log('Image1 field updated successfully:', updateData);
//     //             }
//     //         } catch (updateError) {
//     //             console.error('Error updating image1 field:', updateError.message);
//     //         }
//     //     }
//     // }

//     const handleChange = (e) => {
//         const { name, value } = e.target;
//         const formattedValue = name !== 'slug' ? value.toUpperCase() : value;

//         if (name === 'title' || name === 'serial') {
//             const formattedTitle = formData.title.toLowerCase().replace(/\s+/g, '_');
//             const formattedSerial = formData.serial.toLowerCase().replace(/\s+/g, '_');
//             setFormData((prevData) => ({
//                 ...prevData,
//                 [name]: formattedValue,
//                 slug: `${formattedTitle}_${formattedSerial}`,
//                 // image1: prevData.image1, // Mantenemos el valor existente de image1
//             }));
//         } else {
//             setFormData((prevData) => ({
//                 ...prevData,
//                 [name]: formattedValue,
//             }));
//         }
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();

//         const now = new Date().toISOString();

//         try {
//             const { data, error } = await supabase.from('equipos').update({
//                 updated_at: now,
//                 ...formData,
//             }).eq('id', id);

//             if (error) {
//                 console.error('Error al actualizar en la base de datos:', error);
//             } else {
//                 console.log('Datos actualizados exitosamente:', data);
//                 setShowSuccessDialog(true);
//             }
//         } catch (error) {
//             console.error('Error al actualizar en la base de datos:', error.message);
//         }
//     };

//     const handleAccept = () => {
//         setShowSuccessDialog(false);
//         navigate("/equipos");
//     };

//     const sortedAttendantOptions = attendantOptions.slice().sort();
//     const sortedAreaOptions = areaOptions.slice().sort();
//     const sortedStateOptions = stateOptions.slice().sort();

//     return (
//         <Layout>
// <form className="equipment-form" onSubmit={handleSubmit}>
//     <div className="form-row">
//         <div className="field">
//             <label className='text-xs font-semibold'>
//                 Tipo de Activo:
//                 <input
//                     type="text"
//                     name="active_type"
//                     value={formData.active_type}
//                     onChange={handleChange}
//                 />
//             </label>
//         </div>
//         <div className="field">
//             <label className='text-xs font-semibold'>
//                 Nombre del Equipo:
//                 <input
//                     type="text"
//                     name="title"
//                     value={formData.title}
//                     onChange={handleChange}
//                 />
//             </label>
//         </div>
//         <div className="field">
//             <label className='text-xs font-semibold'>
//                 Cantidad:
//                 <input
//                     type="number"
//                     name="quantity"
//                     value={formData.quantity}
//                     onChange={handleChange}
//                 />
//             </label>
//         </div>
//         <div className="field">
//             <label className='text-xs font-semibold'>
//                 Marca:
//                 <input
//                     type="text"
//                     name="brand"
//                     value={formData.brand}
//                     onChange={handleChange}
//                 />
//             </label>
//         </div>
//     </div>

//     <div className="form-row">
//         <div className="field">
//             <label className='text-xs font-semibold'>
//                 Modelo:
//                 <input
//                     type="text"
//                     name="model"
//                     value={formData.model}
//                     onChange={handleChange}
//                 />
//             </label>
//         </div>
//         <div className="field">
//             <label className='text-xs font-semibold'>
//                 Número de Serie:
//                 <input
//                     type="text"
//                     name="serial"
//                     value={formData.serial}
//                     onChange={handleChange}
//                 />
//             </label>
//         </div>
//         <div className="field">
//             <label className='text-xs font-semibold'>
//                 Medidas:
//                 <input
//                     type="text"
//                     name="measures"
//                     value={formData.measures}
//                     onChange={handleChange}
//                 />
//             </label>
//         </div>
//         <div className="field">
//             <label className='text-xs font-semibold'>
//                 Color:
//                 <input
//                     type="text"
//                     name="color"
//                     value={formData.color}
//                     onChange={handleChange}
//                 />
//             </label>
//         </div>
//     </div>

//     <div className="form-row">
//         <div className="field">
//             <label className='text-xs font-semibold'>
//                 Estado:
//                 <div className="styled-select">
//                     <select
//                         name="state"
//                         value={formData.state}
//                         onChange={handleChange}
//                         className="styled-select"
//                     >
//                         <option value="">Selecciona una opción</option>
//                         {sortedStateOptions.map((option) => (
//                             <option key={option} value={option}>
//                                 {option}
//                             </option>
//                         ))}
//                     </select>
//                 </div>
//             </label>
//         </div>
//         <div className="field">
//             <label className='text-xs font-semibold'>
//                 Condición de Uso:
//                 <div className="styled-select">
//                     <select
//                         name="condition_use"
//                         value={formData.condition_use}
//                         onChange={handleChange}
//                         className="styled-select"
//                     >
//                         <option value="">Selecciona una opción</option>
//                         {yesNoOptions.map((option) => (
//                             <option key={option} value={option}>
//                                 {option}
//                             </option>
//                         ))}
//                     </select>
//                 </div>
//             </label>
//         </div>

//         <div className="field">
//             <label className='text-xs font-semibold'>
//                 Encargado del Equipo:
//                 <div className="styled-select">
//                     <select
//                         name="attendant"
//                         value={formData.attendant}
//                         onChange={handleChange}
//                     >
//                         <option value="">Selecciona una opción</option>
//                         {sortedAttendantOptions.map((option) => (
//                             <option key={option} value={option}>
//                                 {option}
//                             </option>
//                         ))}
//                     </select>
//                 </div>
//             </label>
//         </div>

//         <div className="field">
//             <label className='text-xs font-semibold'>
//                 Área:
//                 <div className="styled-select">
//                     <select
//                         name="area"
//                         value={formData.area}
//                         onChange={handleChange}
//                         className="styled-select"
//                     >
//                         <option value="">Selecciona una opción</option>
//                         {sortedAreaOptions.map((option) => (
//                             <option key={option} value={option}>
//                                 {option}
//                             </option>
//                         ))}
//                     </select>
//                 </div>
//             </label>
//         </div>


//     </div>

//     <div className="form-row">
//         <div className="field">
//             <label className='text-xs font-semibold'>
//                 Slug:
//                 <input
//                     type="text"
//                     name="slug"
//                     value={formData.slug}
//                     onChange={handleChange}
//                     readOnly
//                 />
//             </label>
//         </div>

//         <div className="field">
//             <label className='text-xs font-semibold'>
//                 Imagen 1:
//                 <input
//                     type="file"
//                     name="image1"
//                     // onChange={(e) => handleImageChange(e)}
//                 />
//             </label>
//         </div>
//         {/* <div className="field">
//             <label className='text-xs font-semibold'>
//                 Imagen 2:
//                 <input
//                     type="file"
//                     name="image2"
//                     onChange={(e) => handleImageChange(e)}
//                 />
//             </label>
//         </div> */}
//         <div className="form-row">
//             <button className="bg-green hover:bg-green-800 transition-colors duration-300 text-base cursor-pointer mt-7 text-white font-semibold py-2 px-4 rounded-md w-full" type="submit">Guardar</button>
//         </div>
//         <div className="field"></div>
//         <div className="field"></div>
//     </div>



//     {showSuccessDialog && (
//         <div className="success-dialog">
//             <p>Se ha guardado exitosamente</p>
//             <button onClick={handleAccept} className="bg-green hover:bg-green-800 transition-colors duration-300 text-base cursor-pointer mt-7 text-white font-semibold py-2 px-4 rounded-md w-full">Aceptar</button>
//         </div>
//     )}
// </form>
//         </Layout>
//     );
// };

// export default EquipmentForm;

import { useState, useEffect } from 'react';
import { supabase } from '../client/client';
import './EquipmentForm.css';
import Layout from './Layout';
import { useNavigate, useParams } from 'react-router-dom';

const EquipmentForm = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [formData, setFormData] = useState({
        active_type: '',
        image1: '',
        image2: '',
        area: '',
        brand: '',
        slug: '',
        model: '',
        serial: '',
        measures: '',
        color: '',
        state: '',
        tags: [],
        title: '',
        condition_use: '',
        quantity: 0,
        attendant: '',
    });

    const [showSuccessDialog, setShowSuccessDialog] = useState(false);

    const areaOptions = [
        'TECNOLOGIA DE INFORMACION',
        'CONTABILIDAD',
        'COMERCIAL',
        'CAJA',
        'PROTESTOS',
        'ORIGEN',
        'SECRETARIA',
        'SALON VITOR',
        'SALON CERVESUR',
        'LEGAL',
        'DIRECTORIO 3',
        'SALON 1',
        'SALON 2',
        'SALON 3',
        'AUDITORIO',
        'COCINA',
        'BAÑO PERSONAL',
        'BAÑO SALON CERVESUR',
        'BAÑO AUDITORIO',
        'HALL PRIMERA CASONA',
        'HALL SEGUNDA CASONA',
        'MENSAJERIA',
        'GERENCIA',
        'SUBGERENCIA',
        'ARBITRAJE',
        'ARBITRAJE 2',
        'SECRETARIA ARBITRAL',
    ];

    const attendantOptions = [
        'FABIOLA LÓPEZ CHIRINOS',
        'KATHERINE SUEROS',
        'RUTH VARGAS',
        'ALEJANDRA ALVARADO',
        'GONZALO ZAVALAGA',
        'TAMARA NUÑEZ',
        'DANIELA BENAVENTE',
        'ANTONIO ESCALANTE',
        'JOCELYN CARI',
        'GERALDINE MARQUÉZ',
        'MÓNICA TORRES',
        'GUSTAVO CHIRINOS',
        'BROSWI GALVEZ',
        'SALOMÉ GALDOS',
        'JUAN JAVIER',
        'LUCIANO YNCA',
        'LUDOVINA VILLANUEVA',
        'JIMENA HURTADO',
        'MILUSKA MANRIQUE',
        'JUAN JOSE TICONA',
        'DIEGO VALDIVIA',
        'GIORDANO ARIAS',
    ];

    const stateOptions = ['NUEVO', 'OBSOLETO', 'EN USO'];
    const yesNoOptions = ['SI', 'NO'];

    useEffect(() => {
        const fetchProductData = async () => {
            try {
                const { data, error } = await supabase.from('equipos').select('*').eq('id', id).single();

                if (error) {
                    console.error('Error fetching product data:', error);
                } else {
                    setFormData(data);
                }
            } catch (error) {
                console.error('Error fetching product data:', error.message);
            }
        };

        if (id) {
            fetchProductData();
        }
    }, [id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        const formattedValue = name !== 'slug' ? value.toUpperCase() : value;

        if (name === 'title' || name === 'serial') {
            const formattedTitle = formData.title.toLowerCase().replace(/\s+/g, '_');
            const formattedSerial = formData.serial.toLowerCase().replace(/\s+/g, '_');
            setFormData((prevData) => ({
                ...prevData,
                [name]: formattedValue,
                slug: `${formattedTitle}_${formattedSerial}`,
            }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: formattedValue,
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const now = new Date().toISOString();

        try {
            let response;
            if (id) {
                response = await supabase.from('equipos').update({
                    updated_at: now,
                    ...formData,
                }).eq('id', id);
            } else {
                response = await supabase.from('equipos').insert({
                    created_at: now,
                    updated_at: now,
                    ...formData,
                });
            }

            if (response.error) {
                console.error('Error al insertar/actualizar en la base de datos:', response.error.message);
            } else {
                console.log('Datos insertados/actualizados exitosamente:', response.data);
                setShowSuccessDialog(true);
            }
        } catch (error) {
            console.error('Error al insertar/actualizar en la base de datos:', error.message);
        }
    };

    const handleAccept = () => {
        setShowSuccessDialog(false);
        navigate("/equipos");
    };

    const sortedAttendantOptions = attendantOptions.slice().sort();
    const sortedAreaOptions = areaOptions.slice().sort();
    const sortedStateOptions = stateOptions.slice().sort();

    return (
        <Layout>
            <form className="equipment-form" onSubmit={handleSubmit}>
                <div className="form-row">
                    <div className="field">
                        <label className='text-xs font-semibold'>
                            Tipo de Activo:
                            <input
                                type="text"
                                name="active_type"
                                value={formData.active_type}
                                onChange={handleChange}
                            />
                        </label>
                    </div>
                    <div className="field">
                        <label className='text-xs font-semibold'>
                            Nombre del Equipo:
                            <input
                                type="text"
                                name="title"
                                value={formData.title}
                                onChange={handleChange}
                            />
                        </label>
                    </div>
                    <div className="field">
                        <label className='text-xs font-semibold'>
                            Cantidad:
                            <input
                                type="number"
                                name="quantity"
                                value={formData.quantity}
                                onChange={handleChange}
                            />
                        </label>
                    </div>
                    <div className="field">
                        <label className='text-xs font-semibold'>
                            Marca:
                            <input
                                type="text"
                                name="brand"
                                value={formData.brand}
                                onChange={handleChange}
                            />
                        </label>
                    </div>
                </div>

                <div className="form-row">
                    <div className="field">
                        <label className='text-xs font-semibold'>
                            Modelo:
                            <input
                                type="text"
                                name="model"
                                value={formData.model}
                                onChange={handleChange}
                            />
                        </label>
                    </div>
                    <div className="field">
                        <label className='text-xs font-semibold'>
                            Número de Serie:
                            <input
                                type="text"
                                name="serial"
                                value={formData.serial}
                                onChange={handleChange}
                            />
                        </label>
                    </div>
                    <div className="field">
                        <label className='text-xs font-semibold'>
                            Medidas:
                            <input
                                type="text"
                                name="measures"
                                value={formData.measures}
                                onChange={handleChange}
                            />
                        </label>
                    </div>
                    <div className="field">
                        <label className='text-xs font-semibold'>
                            Color:
                            <input
                                type="text"
                                name="color"
                                value={formData.color}
                                onChange={handleChange}
                            />
                        </label>
                    </div>
                </div>

                <div className="form-row">
                    <div className="field">
                        <label className='text-xs font-semibold'>
                            Estado:
                            <div className="styled-select">
                                <select
                                    name="state"
                                    value={formData.state}
                                    onChange={handleChange}
                                    className="styled-select"
                                >
                                    <option value="">Selecciona una opción</option>
                                    {sortedStateOptions.map((option) => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </label>
                    </div>
                    <div className="field">
                        <label className='text-xs font-semibold'>
                            Condición de Uso:
                            <div className="styled-select">
                                <select
                                    name="condition_use"
                                    value={formData.condition_use}
                                    onChange={handleChange}
                                    className="styled-select"
                                >
                                    <option value="">Selecciona una opción</option>
                                    {yesNoOptions.map((option) => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </label>
                    </div>

                    <div className="field">
                        <label className='text-xs font-semibold'>
                            Encargado del Equipo:
                            <div className="styled-select">
                                <select
                                    name="attendant"
                                    value={formData.attendant}
                                    onChange={handleChange}
                                >
                                    <option value="">Selecciona una opción</option>
                                    {sortedAttendantOptions.map((option) => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </label>
                    </div>

                    <div className="field">
                        <label className='text-xs font-semibold'>
                            Área:
                            <div className="styled-select">
                                <select
                                    name="area"
                                    value={formData.area}
                                    onChange={handleChange}
                                    className="styled-select"
                                >
                                    <option value="">Selecciona una opción</option>
                                    {sortedAreaOptions.map((option) => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </label>
                    </div>
                </div>

                <div className="form-row">
                    <div className="field">
                        <label className='text-xs font-semibold'>
                            Slug:
                            <input
                                type="text"
                                name="slug"
                                value={formData.slug}
                                onChange={handleChange}
                                readOnly
                            />
                        </label>
                    </div>

                    <div className="field">
                        <label className='text-xs font-semibold'>
                            Imagen 1:
                            <input
                                type="file"
                                name="image1"
                            />
                        </label>
                    </div>

                    <div className="form-row">
                        <button className="bg-green hover:bg-green-800 transition-colors duration-300 text-base cursor-pointer mt-7 text-white font-semibold py-2 px-4 rounded-md w-full" type="submit">Guardar</button>
                    </div>
                </div>

                {showSuccessDialog && (
                    <div className="success-dialog">
                        <p>Se ha guardado exitosamente</p>
                        <button onClick={handleAccept} className="bg-green hover:bg-green-800 transition-colors duration-300 text-base cursor-pointer mt-7 text-white font-semibold py-2 px-4 rounded-md w-full">Aceptar</button>
                    </div>
                )}
            </form>
        </Layout>
    );
};

export default EquipmentForm;
