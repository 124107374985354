import React, { useEffect, useState } from "react";
import { supabase } from "../client/client";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useLogin } from "../context/LoginContext";
import Layout from "./Layout";
import Loading from "./Loading";

const SearchResultPage = () => {
    const { term } = useParams();
    const [loading, setLoading] = useState(true);
    const { getSessionAuth } = useLogin();
    const [userData, setUserData] = useState(null);
    const [searchResults, setSearchResults] = useState([]);
    const navigate = useNavigate();
    const [processArea, setProcessArea] = useState([]);
    const [noResults, setNoResults] = useState(false);

    useEffect(() => {
        const sessionAuth = async () => {
            const session = await getSessionAuth();
            if (session) {
                await getUser(session.id);
                setLoading(false);
            } else {
                navigate("/", { replace: true });
            }
        };
        sessionAuth();
    }, []);

    const getUser = async (id) => {
        const { error, data } = await supabase
            .from("users")
            .select()
            .eq(`id`, id)
            .single();
        if (error) throw error.message;
        setUserData(data);
    };

    async function fetchProcess() {
        try {
            if (!userData) return;
            const { data, error } = await supabase
                .from("area_users")
                .select(`id_area, areas(name)`)
                .eq("id_user", userData.id);

            if (error) {
                console.error("Error fetching data:", error.message, error.details);
                return;
            }

            if (data.length === 0) {
                console.warn("No areas found for the user with ID:", userData.id);
                return;
            }

            const areaNames = data.map((item) => item.areas.name);
            setProcessArea(areaNames);
        } catch (error) {
            console.error("Unexpected error:", error);
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            await fetchProcess();
            fetchSearchResults();
        };

        fetchData();
    }, [term, userData, processArea]);


    const fetchSearchResults = async () => {
        try {
            let query = supabase.from("equipos").select("*").ilike("tags", `%${term}%`);

            if (userData?.role !== "admin") {
                query = query.in("area", processArea);
            }

            const { data, error } = await query;

            if (error) {
                console.error("Error fetching data:", error.message, error.details);
                return;
            }

            setSearchResults(data);
            setNoResults(data.length === 0);
        } catch (error) {
            console.error("Unexpected error:", error);
        }
    };

    return (
        <Layout>
            {loading && <Loading />}
            {noResults ? (
                <h2 className="text-[#FF0000] font-semibold text-2xl pb-4">
                    No se encontraron productos con ese nombre, ingrese otro.
                </h2>
            ) : (
                <>
                    <h1 className="text-[#058237] font-semibold text-3xl pb-6">
                        Resultados de la búsqueda:
                    </h1>
                    <div className="grid md:grid-cols-5 grid-cols-3 gap-8">
                        {searchResults.map((result) => (
                            <Link
                                to={`/informacion/${result.id}`}
                                key={result.id}
                                className="flex flex-col gap-2 border border-zinc-300 p-5 hover:bg-zinc-100 transition-colors duration-200"
                            >
                                <img
                                    src={`/images/${result.image ? result.image[0] : "producto.jpeg"
                                        }`}
                                    alt={`${result.title}`}
                                    className="w-full aspect-square"
                                />
                                <h3 className="text-[#058237] font-bold text-lg text-wrap">
                                    {result.title}
                                </h3>
                                <h4 className="text-black font-semibold text-xs">{result.area}</h4>
                            </Link>
                        ))}
                    </div>
                </>
            )}
        </Layout>
    );
};

export default SearchResultPage;
