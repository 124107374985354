import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { supabase } from "../client/client";
import { useLogin } from "../context/LoginContext";
import DataGrid from "react-data-grid";
import * as XLSX from "xlsx";
import DownloadIcon from "./DownloadIcon";
import CreateIcon from "./CreateIcon";
import Layout from "./Layout";
import Loading from "./Loading";

const columns = [
  {
    field: "name",
    name: "Nombre",
    key: "name",
    width: 400,
    renderCell: ({ row }) => {
      return <div>{row.name}</div>;
    },
  },
  { field: "email", name: "Email", width: 420, key: "email" },
  { field: "role", name: "Rol", width: 420, key: "role" },
];

const Usuarios = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  // const { userData, getUser } = useUsers();
  //   const [userData, setUserData] = useState(null);
  const { getSessionAuth } = useLogin();
  const navigate = useNavigate();

  useEffect(() => {
    const sessionAuth = async () => {
      const session = await getSessionAuth();
      if (session) {
        // await getUser(session.id);
        setLoading(false);
      } else {
        navigate("/", { replace: true });
      }
    };
    sessionAuth();
  }, []);

  //   const getUser = async (id) => {
  //     const { error, data } = await supabase
  //       .from("users")
  //       .select()
  //       .eq(`id`, id)
  //       .single();
  //     if (error) throw error.message;
  //     setUserData(data);
  //   };

  // console.log(user)

  // useEffect(() => {
  //     if (user) {
  //         navigate("/usuarios", { replace: true });
  //         getUser(user.id);
  //     }
  //     else {
  //         navigate("/login");
  //     }
  // }, [user])

  const handleDownloadExcel = () => {
    const sheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, sheet, "Usuarios");
    XLSX.writeFile(workbook, "usuarios.xlsx");
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data, error } = await supabase.from("users").select("*");

        if (error) {
          console.error("Error fetching data:", error);
        } else {
          setData(data);
        }
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    };

    fetchData();
  }, []);

  return (
    <Layout>
      {loading && <Loading />}
      <div className="flex items-center justify-between mb-10">
        <h1 className="text-[#058237] font-semibold text-3xl">
          LISTADO DE USUARIOS
        </h1>
        <div className="flex items-center gap-2">
          <Link to="/formulario-usuarios">
            <button className="bg-green hover:bg-green-800 transition-colors duration-300 text-sm cursor-pointer h-8 flex gap-1 items-center px-3 py-1 text-white">
              <CreateIcon className="w-4" />
              Crear usuario
            </button>
          </Link>
          <button
            onClick={handleDownloadExcel}
            className="bg-green hover:bg-green-800 transition-colors duration-300 text-sm cursor-pointer h-8 flex gap-1 items-center px-3 py-1 text-white"
          >
            <DownloadIcon className="w-5" />
            Descargar Excel
          </button>
        </div>
      </div>
      <DataGrid rows={data} columns={columns} className="rdg-light" />
    </Layout>
  );
};

export default Usuarios;
