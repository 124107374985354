import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DataGrid from "react-data-grid";
import "jspdf-autotable";
import { supabase } from "../client/client";
import "react-data-grid/lib/styles.css";


const FetchSoftware = () => {
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [filters, setFilters] = useState({
        attendant: "",
        title: "",
        description: "",
        license: "",
    });


    const columnsSoftware = [
        { field: "attendant", name: "Encargado", width: 250, key: "attendant" },
        {
            field: "title",
            name: "Nombre",
            width: 150,
            key: "title",
            renderCell: ({ row }) => {
                return (
                    <Link to={`/equipos/${row.id_equipo}/editar`} underline="always">
                        {row.title}
                    </Link>
                );
            },
        },
        { field: "description", name: "Descripción", width: 400, key: "description" },
        { field: "license", name: "Licencia", width: 200, key: "license" },
        { field: "license_duration", name: "Duración de licencia", width: 250, key: "license_duration" },
        { field: "date_expiry", name: "Fecha de caducidad", width: 250, key: "date_expiry" },
    ];
    const gridStyle = {
        minHeight: '500px',
    };

    const fetchData = async () => {
        try {
            const { data: licenseData, error: licenseError } = await supabase
                .from('licencias')
                .select('*');
            if (licenseError) {
                throw licenseError;
            }
            const licenseIds = licenseData.map(license => license.id_equipo);
            const { data: equiposData, error: equiposError } = await supabase
                .from('equipos')
                .select('id, title, attendant')
                .in('id', licenseIds);
            if (equiposError) {
                throw equiposError;
            }
            const combinedData = licenseData.map((license) => {
                const equipo = equiposData.find((equipo) => equipo.id === license.id_equipo);
                return {
                    ...license,
                    title: equipo.title,
                    attendant: equipo.attendant,
                };
            });

            // Filtrar los datos en base a los filtros
            const filteredData = combinedData.filter((row) =>
                Object.entries(filters).every(([key, value]) =>
                    row[key].toLowerCase().includes(value.toLowerCase())
                )
            );

            // Ordenar los datos en base a la fecha de caducidad
            const sortedData = filteredData.slice().sort((a, b) => new Date(a.date_expiry) - new Date(b.date_expiry));

            setData(combinedData); // Actualizar data
            setFilteredData(sortedData); // Actualizar filteredData
        } catch (error) {
            console.error("Error fetching data:", error.message);
        }
    };

    const handleFilterChange = (filterName, value) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            [filterName]: value,
        }));
    };


    useEffect(() => {
        fetchData();
    }, [filters])



    return (
        <>
            <div className="flex items-center gap-2 mb-4 mt-5">
                <input
                    type="text"
                    placeholder="Filtrar por encargado"
                    value={filters.attendant}
                    onChange={(e) => handleFilterChange("attendant", e.target.value)}
                />
                <input
                    type="text"
                    placeholder="Filtrar por nombre"
                    value={filters.title}
                    onChange={(e) => handleFilterChange("title", e.target.value)}
                />
                <input
                    type="text"
                    placeholder="Filtrar por descripción"
                    value={filters.description}
                    onChange={(e) => handleFilterChange("description", e.target.value)}
                />
                <input
                    type="text"
                    placeholder="Filtrar por licencia"
                    value={filters.license}
                    onChange={(e) => handleFilterChange("license", e.target.value)}
                />
            </div>

            <DataGrid rows={filteredData} columns={columnsSoftware} style={gridStyle} rowHeight={50} minHeight={400} className="rdg-light" />
        </>
    )
}

export default FetchSoftware