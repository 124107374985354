import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "./Layout";

const NotFound = () => {
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      navigate("/login");
    }, 5000);
  }, [navigate]);
  return (
    <Layout>
      <h1 className="text-[#058237] font-bold text-4xl">
        Esta pagina no existe
      </h1>
    </Layout>
  );
};

export default NotFound;
