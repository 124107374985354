import { useNavigate } from "react-router-dom";
import { useLogin } from "../context/LoginContext";
import { useEffect, useState } from "react";
import { supabase } from "../client/client";
import SummaryTile from "./SummaryTile";
import PhoneIcon from "./PhoneIcon";
import UserIcon from "./UserIcon";
import LaptopIcon from "./LaptopIcon";
import MouseIcon from "./MouseIcon";
import PrinterIcon from "./PrinterIcon";
import KeyboardIcon from "./KeyboardIcon";
import ClockIcon from "./ClockIcon";
import Layout from "./Layout";
import Loading from "./Loading";

const Dashboard = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { getSessionAuth } = useLogin();

  const [numberOfUsers, setNumberOfUsers] = useState(0);
  const [numberOfProducts, setNumberOfProducts] = useState(0);
  const [numberOfLaptops, setNumberOfLaptops] = useState(0);
  const [numberOfMouses, setNumberOfMouses] = useState(0);
  const [numberOfKeyboards, setNumberOfKeyboards] = useState(0);
  const [numberOfPrinters, setNumberOfPrinters] = useState(0);
  const [refreshIn, setRefreshIn] = useState(30);

  useEffect(() => {
    const sessionAuth = async () => {
      const session = await getSessionAuth();
      if (session) {
        setLoading(false);
      } else {
        navigate("/", { replace: true });
      }
    };
    sessionAuth();
  }, []);

  const fetchData = async () => {
    const { data: users, error: usersError } = await supabase
      .from("users")
      .select("id");

    const { data: products, error: productsError } = await supabase
      .from("equipos")
      .select("id");

    const { data: laptops, error: laptopsError } = await supabase
      .from("equipos")
      .select("id")
      .ilike("title", "%LAPTOP%");

    const { data: mouses, error: mousesError } = await supabase
      .from("equipos")
      .select("id")
      .ilike("title", "%MOUSE%");

    const { data: keyboards, error: keyboardsError } = await supabase
      .from("equipos")
      .select("id")
      .ilike("title", "%TECLADO%");

    const { data: printers, error: printersError } = await supabase
      .from("equipos")
      .select("id")
      .ilike("title", "%IMPRESORA%");

    if (!usersError) setNumberOfUsers(users.length || 0);
    if (!productsError) setNumberOfProducts(products.length || 0);
    if (!laptopsError) setNumberOfLaptops(laptops.length || 0);
    if (!mousesError) setNumberOfMouses(mouses.length || 0);
    if (!keyboardsError) setNumberOfKeyboards(keyboards.length || 0);
    if (!printersError) setNumberOfPrinters(printers.length || 0);
  };

  useEffect(() => {
    fetchData();

    const interval = setInterval(() => {
      fetchData();
      setRefreshIn((refreshIn) => (refreshIn > 0 ? refreshIn - 1 : 30));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Layout>
      {loading && <Loading />}
      <div className="flex justify-center">
        <div className="grid grid-cols-3 gap-5">
          <div>
            <SummaryTile
              title={numberOfUsers}
              subTitle="Usuarios"
              icon={<UserIcon className={"w-20 fill-green"} />}
            />

            <SummaryTile
              title={numberOfProducts}
              subTitle="Cantidad de equipos"
              icon={<PhoneIcon className={"w-20 fill-blue-600"} />}
            />
          </div>
          <div>
            <SummaryTile
              title={numberOfLaptops}
              subTitle="Cantidad de laptops"
              icon={<LaptopIcon className={"w-20 fill-orange-500"} />}
            />

            <SummaryTile
              title={numberOfMouses}
              subTitle="Cantidad de mouses"
              icon={<MouseIcon className={"w-20 fill-gray-200"} />}
            />
          </div>

          <div>
            <SummaryTile
              title={numberOfPrinters}
              subTitle="Cantidad de impresoras"
              icon={<PrinterIcon className={"w-20 stroke-orange-500"} />}
            />

            <SummaryTile
              title={numberOfKeyboards}
              subTitle="Cantidad de teclados"
              icon={<KeyboardIcon className={"w-20 fill-orange-500"} />}
            />
          </div>

          <div>
            <SummaryTile
              title={refreshIn}
              subTitle="Actualización en: "
              icon={<ClockIcon color="secondary" className={"w-20 stroke-gray-700"} />}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Dashboard;
